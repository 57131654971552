<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div
    v-if="$breakpoint.fromDesktop()"
    data-cy="navigation"
    class="navigation__component"
    @mouseleave="debouncedCloseFlyout"
    @keydown.esc="resetFocusAndCloseFlyout"
  >
    <nav :aria-labelledby="upperNavId">
      <span :id="upperNavId" aria-hidden="true" class="visually-hidden">
        Hauptmenü
      </span>
      <ul class="navigation__upper-part" :class="{ selfcare: selfcare }">
        <li
          v-for="(item, index) in navigationItems"
          :key="index"
          class="navigation__item upper"
        >
          <component
            :is="hasFlyout(item) ? 'button' : 'a'"
            :ref="getRefName(index)"
            :data-cy="`upper-nav-item-${index}`"
            :tabindex="selfcare ? 1 : index + 1"
            class="navigation__link upper"
            :class="{
              selected: index === current,
              selfcare: selfcare,
              ...navLinkClasses(item)
            }"
            :href="getHref(item)"
            :aria-haspopup="hasFlyout(item) ? true : undefined"
            :aria-expanded="
              hasFlyout(item) && index === current
                ? index === current
                : undefined
            "
            :aria-current="
              !hasFlyout(item) && currentPage(item) ? 'page' : undefined
            "
            @keydown.tab.shift="closeFlyout"
            @mouseover="handleNavItemHover(index)"
            @touchstart.prevent="handleNavItemEvent(item, index)"
            @click.prevent.stop="handleNavItemEvent(item, index)"
          >
            {{ item.title }}
          </component>
        </li>
      </ul>
    </nav>

    <nav v-if="selfcare" :aria-labelledby="lowerNavId">
      <span :id="lowerNavId" aria-hidden="true" class="visually-hidden">
        Untermenü
      </span>
      <ul class="navigation__lower-part">
        <li
          v-for="(item, index) in lowerNaviagtionItems"
          :key="index"
          class="navigation__item lower"
        >
          <a
            v-if="item.external === true"
            :href="item.path"
            :class="navLinkClasses(item)"
            :aria-current="currentPage(item) && selfcare ? 'page' : undefined"
            class="navigation__link lower"
            :data-cy="`lower-nav-item-${index}`"
            :tabindex="2"
            v-html="item.title"
          />
          <router-link
            v-else
            :class="navLinkClasses(item)"
            class="navigation__link lower"
            :data-cy="`lower-nav-item-${index}`"
            :to="item.name ? item : item.path"
            :tabindex="2"
            v-html="item.title"
          ></router-link>
        </li>
      </ul>
    </nav>

    <NavigationFlyout
      v-if="!selfcare"
      :items="flyoutItems"
      :selected="current"
      :current-page="exactCurrentPage"
      :current-tab-index="current + 1"
      :aria-hidden="isFlyoutEmpty ? 'true' : undefined"
      @mouseover="keepFlyoutOpen"
      @tabbed-out="closeFlyout"
      @keydown.esc="keyboardClose"
    />
  </div>

  <!--- Mobile --->
  <NavigationDrawer v-else ref="drawer" :navigation-items="navigationItems" />
</template>

<script>
import {
  currentPage,
  currentPageOrSubpage,
  exactCurrentPage
} from './helper.js'
import { uniqueId } from 'src/lib/helpers'
import NavigationDrawer from './drawer.vue'
import NavigationFlyout from './flyout.vue'
import debounce from 'debounce'
import navigation_json from './navigation.json'

export default {
  name: 'NavigationHeader',
  components: {
    NavigationDrawer,
    NavigationFlyout
  },
  props: {
    selfcare: {
      type: Boolean,
      default: false
    },
    portal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    let navigationItems = []

    if (this.selfcare) {
      navigationItems = navigation_json.selfcare
    } else if (this.portal) {
      navigationItems = navigation_json.portal
    } else {
      navigationItems = { titel: 'Ups, something went wrong', path: '/' }
    }

    navigationItems = this.filterForBrand(navigationItems)

    return {
      current: -1,
      lastCurrent: -1,
      navigationItems,
      upperNavId: 'upper-nav-' + uniqueId(),
      lowerNavId: 'lower-nav-' + uniqueId(),
      debouncedCloseFlyout: undefined,
      hoverTimestamp: new Date(),
      a11yFlyoutCloseDelay: 300,
      a11yClickBlockedDelay: 600,
      routeChanged: false,
      closedByKeyboard: false
    }
  },
  computed: {
    lowerNaviagtionItems() {
      const items = this.navigationItems.filter(page =>
        this.currentPageOrSubpage(page)
      )

      if (items && items.length > 0) {
        return items[0].items
      }

      return []
    },
    flyoutItems() {
      const items = []

      for (let item of this.navigationItems) {
        items.push(item.flyout ? item.flyout : [])
      }

      return items
    },
    isFlyoutEmpty() {
      return this.flyoutItems.length === 0
    }
  },
  watch: {
    $route() {
      this.routeChanged = true
      this.closeFlyout()
    },
    current(newValue, oldValue) {
      this.lastCurrent = oldValue
      if (newValue >= 0) {
        // flyout opens
        document.addEventListener('click', this.closeFlyout)
      } else {
        // flyout closes
        document.removeEventListener('click', this.closeFlyout)
      }
    }
  },
  created() {
    this.debouncedCloseFlyout = debounce(
      this.closeFlyout,
      this.a11yFlyoutCloseDelay
    )
  },
  methods: {
    hasFlyout(item) {
      return !!item.flyout
    },
    getHref(item) {
      if (this.hasFlyout(item)) {
        return undefined
      }

      return item.name
        ? this.$router.resolve({ name: item.name }).href
        : item.path
    },
    getRefName(index) {
      return `upperNavItem${index}`
    },
    currentPage(item) {
      return currentPage(this.$route, item)
    },
    exactCurrentPage(item) {
      return exactCurrentPage(this.$router, this.$route, item)
    },
    currentPageOrSubpage(item) {
      return currentPageOrSubpage(this.$router, this.$route, item)
    },
    navLinkClasses(item) {
      const isCurrent = this.currentPageOrSubpage(item)

      return {
        disabled: this.isDisabled() && !isCurrent
      }
    },
    isDisabled() {
      return false
    },
    hasFlyoutItems(index) {
      return this.flyoutItems[index].length > 0
    },
    keepFlyoutOpen() {
      if (this.current < 0 && !this.routeChanged) {
        if (this.closedByKeyboard) {
          this.closedByKeyboardlosed = false
        } else {
          // open it again if it started closing (animation running, but current was already resetted)
          this.openFlyout(this.lastCurrent)
          this.routeChanged = false
        }
      }
    },
    resetFocusAndCloseFlyout() {
      if (this.current >= 0) {
        const current = this.current

        this.closeFlyout()
        // this is neccessary since ScreenReaders will announce "expaded: true" without delay
        setTimeout(() => {
          this.$refs[this.getRefName(current)][0].focus()
        }, 300)
      }
    },
    openFlyout(index) {
      this.debouncedCloseFlyout.flush()
      this.current = index
    },
    closeFlyout() {
      this.current = -1
    },
    toggleFlyout(index) {
      const timeDiff = new Date() - this.hoverTimestamp

      if (this.current !== index) {
        this.openFlyout(index)
      } else if (timeDiff > this.a11yClickBlockedDelay) {
        this.closeFlyout()
      }
    },
    handleNavItemHover(index) {
      this.hoverTimestamp = new Date()
      this.openFlyout(index)
    },
    handleNavItemEvent(item, index) {
      if (!this.hasFlyoutItems(index)) {
        this.followLink(item)
      } else {
        this.toggleFlyout(index)
      }
    },
    followLink(item) {
      if (item.external === true) {
        window.location.href = item.path
      } else if (item.name && item.name !== this.$route.name) {
        this.$router.push(item)
      } else if (item.path && item.path !== this.$route.path) {
        this.$router.push(item.path)
      }
    },
    filterForBrand(items) {
      const forBrand = x => {
        if (this.$brand.isFonic() && x.fonicMobileOnly === true) {
          return false
        }

        if (this.$brand.isFonicMobile() && x.fonicOnly === true) {
          return false
        }

        return true
      }

      const forEmptyColumn = column => {
        return column.length > 0
      }

      if (items && items.length > 0) {
        const filteredRootLevel = items.filter(forBrand)

        for (const item of filteredRootLevel) {
          if (item.items) {
            item.items = this.filterForBrand(item.items)
          }

          if (item.flyout) {
            item.flyout = item.flyout
              .map(column => {
                return column.filter(forBrand)
              })
              .filter(forEmptyColumn)
          }
        }

        return filteredRootLevel
      }

      return []
    },
    beforeUnmount() {
      document.removeEventListener('click', this.closeFlyout)
    },
    keyboardClose() {
      this.closedByKeyboard = true
      this.closeFlyout()
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes popup-upper {
  from {
    bottom: -8px;
  }

  to {
    bottom: -5px;
  }
}

@keyframes popup-lower {
  from {
    bottom: -8px;
  }

  to {
    bottom: -5px;
  }
}

.navigation__component {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}

.navigation__item {
  display: flex;
  flex-grow: 1;

  &.upper {
    flex-basis: 33%;
    align-self: stretch;

    &:first-child {
      border-radius: 5px 0 0;
    }

    &:last-child {
      border-radius: 0 5px 0 0;
    }
  }
}

.navigation__link {
  @include font-normal;

  color: var(--root-color);
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &.disabled {
    cursor: default;
    color: $c_grey;
  }

  &.upper {
    cursor: pointer;
    padding: 15px 10px 10px;
    margin: 5px;
    background-color: var(--background-primary);
  }

  &.lower {
    font-size: 1rem;
    padding: 5px 0;
    margin: 5px 1px;

    &:first-child {
      padding-left: 5px;
      margin-left: 5px;
    }

    &:last-child {
      padding-right: 5px;
      margin-right: 5px;
    }
  }

  &.highlight,
  &:hover {
    font-weight: bold;
    color: var(--navigation-active);

    @include breakpoint($from-desktop) {
      font-weight: normal;
    }

    &.upper:not(.selfcare)::after {
      content: '';
      width: calc(100% - 6px);
      position: absolute;
      height: 4px;
      background-color: var(--navigation-active);
      left: 3px;
      bottom: -5px;
      animation-name: popup-upper;
      animation-duration: 200ms;
    }

    &.lower::after {
      content: '';
      position: absolute;
      height: 4px;
      background-color: var(--navigation-active);
      animation-name: popup-lower;
      animation-duration: 200ms;
      width: calc(100% - 4px);
      left: 2px;
      bottom: -5px;
    }
  }
}

@include is-dark {
  .navigation__link.selfcare.highlight,
  .navigation__link.selfcars:hover {
    text-shadow: 0 0 20px;
  }
}

.navigation__upper-part,
.navigation__lower-part {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: hidden;
  z-index: 15;

  span {
    display: flex;
    padding: 0 5px;
  }
}

.navigation__upper-part {
  font-size: min(1.6rem, 32px);
  height: 58px;
  justify-content: center;
  background-color: var(--background-primary);
  border-radius: 5px 5px 0 0;

  &:not(.selfcare) {
    border-radius: 5px;
  }
}

.navigation__lower-part {
  background-color: var(--navigation-lower-bg);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #cad7da;
}
</style>
