<template>
  <div
    v-if="classic"
    class="flyout__classic-wrapper"
    :data-cy="`flyout-item-${classic.title}`"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="followLink(classic.path)"
  >
    <div class="classic">
      <ImageComponent width="55" :src="ClassicImage" />

      <span class="ribbon">+ 10 € Startbonus</span>

      <NavigationItem
        :item="classic"
        :selected="hover || currentPage(classic)"
        :tab-index="tabIndex"
      />

      <span class="classic__subtitel">
        Ohne Fixkosten &amp; Vertragslaufzeit
      </span>
    </div>
  </div>

  <div v-else class="flyout__column-wrapper" @touchend.self.prevent="() => {}">
    <div class="flyout__column">
      <NavigationItem
        v-for="(item, index) in items"
        :key="`${item.title}-${index}`"
        :item="item"
        :selected="currentPage(item)"
        :data-cy="`flyout-item-${item.title}`"
        :tab-index="tabIndex"
      />
    </div>
  </div>
</template>

<script>
import ClassicImage from '/resources/${BRAND_CODE}-classic-small.webp'
import ImageComponent from 'src/components/common/image/image.vue'
import NavigationItem from './navigation_item.vue'
export default {
  name: 'NavigationColumn',
  components: {
    NavigationItem,
    ImageComponent
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    currentPage: {
      type: Function,
      required: true
    },
    tabIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      hover: false,
      ClassicImage
    }
  },
  computed: {
    classic() {
      return this.items[0] && this.items[0].classic ? this.items[0] : undefined
    }
  },
  methods: {
    followLink(path) {
      if (path !== this.$route.path) {
        this.$router.push(path)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flyout__column,
.classic {
  display: table;
  padding: 0 5px 0 20px;
  margin: 40px auto 1px;
}

.flyout__classic-wrapper {
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    height: 155px;
    width: 2px;
    background-color: #dbe2e8;
    right: 0;
    bottom: 9px;
  }
}

.flyout__column-wrapper,
.flyout__classic-wrapper {
  flex-grow: 1;
  position: relative;
}

.flyout__classic-wrapper + .flyout__column-wrapper {
  padding-left: 6px;
}

.flyout__column-wrapper:last-child {
  padding-right: 10px;
}

.classic {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-right: 20px;
  padding-left: 20px;

  .classic__subtitel {
    color: var(--navigation-links-secondary);
    max-width: 170px;
    text-align: center;
    line-height: 1.2;

    @include fonic-mobile {
      color: var(--root-color);
    }
  }

  .ribbon {
    color: $root-color;
    font-size: min(0.9rem, 25px);
    line-height: 23px;
    padding: 3px 10px 0;
    border-radius: 13px;
    background: $ribbon-color;
    margin: 8px 0;
  }
}
</style>
