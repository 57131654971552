<template>
  <div
    ref="flyout"
    data-cy="flyout"
    class="flyout__animation"
    @touchend.self.prevent="() => {}"
    @mouseover="$emit('mouseover', $event)"
  >
    <div ref="needle" class="needle" />

    <nav
      class="flyout__wrapper"
      :class="[flyoutOpen ? 'flyout__wrapper--open' : '']"
      :aria-labelledby="flyoutId"
    >
      <span :id="flyoutId" aria-hidden="true" class="visually-hidden">
        Untermenü
      </span>

      <div
        v-for="(flyout, index) in items"
        :key="`flyout-${index}`"
        class="flyout__content"
        :class="{ hidden: !isSelected(index) }"
      >
        <NavigationColumn
          v-for="(column, i) in flyout"
          :key="`flyout-column-${i}`"
          :items="column"
          :current-page="exactCurrentPage"
          :tab-index="currentTabIndex"
        />
      </div>
      <div ref="primaryLinks" class="flyout__primary-link-group">
        <router-link
          v-if="showTariffOverviewLink"
          class="flyout__primary-link"
          data-cy="primaryLinkTariffOverview"
          :to="{ name: 'PortalTariffOverview' }"
          :tabindex="currentTabIndex"
        >
          <Icon name="keyboard__arrow--right" height="23" up="3" />
          Zur Prepaid-Tarifübersicht
        </router-link>
        <router-link
          v-if="showTariffOverviewLink"
          class="flyout__primary-link"
          :tabindex="currentTabIndex"
          :to="{ name: 'PrepaidHardwareLandingPage' }"
        >
          <Icon name="keyboard__arrow--right" height="23" up="3" />
          Prepaid Handys und Tablets
        </router-link>
        <router-link
          v-if="showServiceOverviewLink"
          class="flyout__primary-link"
          data-cy="primaryLinkServiceOverview"
          :tabindex="currentTabIndex"
          :to="{ name: 'PortalServiceHome' }"
        >
          <Icon name="keyboard__arrow--right" height="23" up="3" />
          Zur Service-Übersicht
        </router-link>
        <router-link
          v-if="showHelpLink"
          class="flyout__primary-link"
          data-cy="primaryLinkServiceOverview"
          :tabindex="currentTabIndex"
          :to="{ name: 'PortalServiceHome' }"
        >
          <Icon name="keyboard__arrow--right" height="23" up="3" />
          Zur Service-Übersicht
        </router-link>
      </div>
    </nav>
  </div>
</template>

<script>
import { exactCurrentPage } from './helper'
import { uniqueId } from 'src/lib/helpers'
import Icon from '../icon/icon.vue'
import NavigationColumn from './navigation_column.vue'

export default {
  name: 'NavigationFlyout',
  components: {
    NavigationColumn,
    Icon
  },
  props: {
    selected: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    currentTabIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  emits: ['mouseover', 'tabbed-out'],
  data() {
    return {
      flyoutId: 'flyout-' + uniqueId(),
      lastInteractiveElement: undefined,
      flyoutOpen: false
    }
  },
  computed: {
    showTariffOverviewLink() {
      return this.selected === 0
    },
    showServiceOverviewLink() {
      return this.selected === 1
    },
    showHelpLink() {
      return this.selected === 2
    }
  },
  watch: {
    selected(elem) {
      if (elem >= 0 && this.items[elem].length > 0) {
        this.renderNeedle(elem)
        this.$nextTick(this.addFocusoutListener)
      } else {
        this.resetNeedle()
        this.removeFocusoutListener()
      }
    }
  },
  beforeUnmount() {
    this.removeFocusoutListener()
  },
  methods: {
    emitTabbedOut() {
      this.$emit('tabbed-out')
    },
    addFocusoutListener() {
      const linkList = this.$refs.primaryLinks.querySelectorAll(
        'button, a, [tabindex]:not([tabindex="-1"])'
      )

      if (linkList.length > 0) {
        this.lastInteractiveElement = linkList[linkList.length - 1]
        this.lastInteractiveElement.addEventListener(
          'focusout',
          this.emitTabbedOut
        )
      }
    },
    removeFocusoutListener() {
      if (this.lastInteractiveElement) {
        this.lastInteractiveElement.removeEventListener(
          'focusout',
          this.emitTabbedOut
        )
        this.lastInteractiveElement = undefined
      }
    },
    exactCurrentPage(item) {
      return exactCurrentPage(this.$router, this.$route, item)
    },
    filteredItems(flyoutColumns) {
      let list = []

      for (let column of flyoutColumns) {
        if (column.length > 0) {
          list.push(column)
        }
      }

      return list
    },
    isSelected(index) {
      return index === this.selected
    },
    renderNeedle(index) {
      const currentWidth = this.$refs.flyout.getBoundingClientRect().width

      this.$refs.needle.style['margin-top'] = 5 + 'px'
      this.$refs.needle.style.top = '-19px'
      this.$refs.needle.style.transform = 'rotate(45deg) scaleY(1)'
      this.$refs.flyout.style.transform = 'scaleY(1)'
      this.$refs.flyout.style['margin-top'] = 27 + 'px'
      this.flyoutOpen = true
      const menuItemWidth = currentWidth / 3

      this.$refs.needle.style.left =
        menuItemWidth * index + menuItemWidth / 2 - 20 + 'px'
    },
    resetNeedle() {
      this.$refs.needle.style['margin-top'] = '10px'
      this.$refs.needle.style.transform = 'scaleY(0)'
      this.$refs.needle.style.top = 20 + 'px'
      this.$refs.flyout.style['margin-top'] = '-7px'
      this.$refs.flyout.style.transform = 'scaleY(0)'
      setTimeout(() => {
        this.flyoutOpen = false
      }, 600)
    }
  }
}
</script>

<style lang="scss" scoped>
.flyout__animation {
  display: flex;
  align-items: flex-start;
  position: relative;
  border-radius: 5px;
  margin-top: -7px;
  background-color: var(--background-primary);
  backface-visibility: hidden;
  box-shadow: 0 9px 11px -4px rgb(0 0 0 / 76%);
  transition: all 500ms ease-in-out;
  transform-origin: top;
  transform: scaleY(0);
}

.needle {
  position: absolute;
  top: 0;
  width: 35px;
  height: 35px;
  margin-top: 5px;
  transform: scaleY(0);
  background-color: var(--background-primary);
  border-radius: 6px 0;
  transition: left 500ms ease-in-out;
}

.flyout__wrapper {
  @include font-normal;

  width: 100%;
  max-height: 100%;
  overflow: hidden;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translate3d(0, 0, 0);
}

.flyout__wrapper--open {
  min-height: 200px;
}

.flyout__content {
  display: flex;
  justify-content: space-around;
  font-size: min(1rem, 32px);
  flex-wrap: wrap;

  &.hidden {
    display: none;
  }
}

.flyout__primary-link-group {
  display: flex;
  justify-content: space-around;
}

.flyout__primary-link {
  color: var(--root-color);
  text-align: center;
  font-size: min(1.1rem, 32px);
  margin-bottom: 30px;
  margin-top: 29px;
  padding-right: 3px;
  text-decoration: none;

  a,
  .icon {
    color: var(--root-color);
  }

  .icon {
    margin-right: -6px;
  }
}
</style>
