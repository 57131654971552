<template>
  <component :is="stage" v-if="showStage" data-cy="stage" v-bind="attributes" />
</template>

<script>
import { markRaw } from 'vue'
export default {
  name: 'StageWrapper',
  data() {
    return {
      showStage: false,
      stage: undefined,
      attributes: undefined
    }
  },
  created() {
    this.$bus.on('show-stage', this.onShowStage)
    this.$bus.on('hide-stage', this.hideStage)
    this.$bus.on('page-change', this.hideStage)
  },
  beforeUnmount() {
    this.$bus.off('show-stage', this.onShowStage)
    this.$bus.off('hide-stage', this.hideStage)
    this.$bus.off('page-change', this.hideStage)
  },
  methods: {
    hideStage() {
      this.stage = undefined
      this.showStage = false
    },
    onShowStage(stage, attrs) {
      this.stage = markRaw(stage)
      this.attributes = attrs
      this.showStage = true
    }
  }
}
</script>
