<template>
  <!-- External Link or Solution Manager Link -->
  <div
    v-if="isExternalLink || isSolutionManagerLink"
    class="nav-item__wrapper"
    :class="{
      'nav-item--highlighted': isHighlighted
    }"
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="openSolutionManager"
    @keydown.enter="openSolutionManager"
  >
    <Icon
      v-if="isHighlighted"
      :class="{ 'nav-icon--heading': item.heading }"
      class="nav-item__icon"
      name="keyboard__arrow--right"
      height="23"
    />
    <a
      class="flyout__link"
      :tabindex="tabIndex"
      :data-cy="dataCy"
      :href="item.path"
      @focus="hover = true"
      @blur="hover = false"
      v-html="item.title"
    />
  </div>

  <!-- No Link -->
  <span
    v-else-if="!isLink"
    class="flyout__link"
    :class="{ 'nav-item--heading': item.heading }"
    v-html="item.title"
  />

  <!-- Router Link -->
  <router-link
    v-else
    v-slot="{ href, navigate }"
    :to="item.name ? item : item.path"
    custom
  >
    <div
      class="nav-item__wrapper"
      :class="{ 'nav-item--highlighted': isHighlighted }"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <Icon
        v-if="isHighlighted"
        :class="{ 'nav-icon--heading': item.heading }"
        class="nav-item__icon"
        name="keyboard__arrow--right"
        height="23"
        :up="item.heading && '2'"
      />
      <a
        class="flyout__link flyout__link--hover"
        :tabindex="tabIndex"
        :data-cy="dataCy"
        :class="{ 'nav-item--heading': item.heading }"
        :href="href"
        @focus="hover = true"
        @blur="hover = false"
        @click="navigate"
        v-html="item.title"
      />
    </div>
  </router-link>
</template>

<script>
import Icon from '../icon/icon.vue'

export default {
  name: 'NavigationItem',
  components: { Icon },
  props: {
    item: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    dataCy: {
      type: String,
      default: undefined
    },
    tabIndex: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      hover: false
    }
  },
  computed: {
    isLink() {
      return this.item.path || this.item.name
    },
    isSolutionManagerLink() {
      return this.item.title === 'Suche'
    },
    isExternalLink() {
      return this.item.external
    },
    isHighlighted() {
      return this.selected || this.hover
    }
  },
  methods: {
    openSolutionManager() {
      if (this.isSolutionManagerLink) {
        this.$bus.emit('open-solution-manager', undefined, 'skiplink')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$nav_breakpoint: max-width 970px;

.flyout__link {
  color: var(--navigation-links-secondary);
  background-color: var(--background-primary);
  line-height: calc-rem-min(19px, 32px);
  padding-top: 3px;
  padding-right: 3px;
  margin: 3px;
  position: relative;
  text-decoration: none;

  @include fonic-mobile {
    color: var(--root-color);
  }

  @include breakpoint($nav_breakpoint) {
    font-size: 0.9rem;
  }
}

.nav-item--heading {
  @include font-bold;

  color: var(--root-color);
  font-size: min(1.2rem, 34px);

  @include breakpoint($nav_breakpoint) {
    font-size: 1rem;
  }
}

.nav-item__wrapper {
  display: flex;
  position: relative;
  font-size: min(1rem, 32px);
}

.nav-item__icon {
  color: var(--navigation-links-secondary);
  position: absolute;
  top: -1px;
  left: -23px;

  @include fonic-mobile {
    color: var(--root-color);
  }

  @include breakpoint($nav_breakpoint) {
    top: -3px;
  }

  &.nav-icon--heading {
    color: var(--root-color);
  }
}

.nav-item--highlighted {
  .flyout__link,
  .nav-item__icon {
    color: var(--root-color);
  }
}
</style>
