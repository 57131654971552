<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <div
    ref="navigationDrawer"
    class="navigation__wrapper"
    @keydown="handleKeyEvent"
    @touchstart="handleNavItemTouch"
    @touchmove="handleTouchMove"
  >
    <span :id="menuId" aria-hidden="true" class="visually-hidden"> Menü </span>

    <ul
      :aria-labelledby="menuId"
      class="navigation__drawer"
      @click.stop="() => {}"
    >
      <li
        v-for="(item, index) in navigationItems"
        :key="`items-${index}`"
        class="navigation__drawer-item-wrapper"
      >
        <ButtonComponent
          v-if="hasSubitems(item)"
          class="navigation__drawer-item"
          button-type="look-like-link"
          :clickhandler="() => toggleMenu(index)"
          :aria-expanded="`${open === index}`"
        >
          <span>{{ item.title }}</span>
          <Icon
            class="navigation__drawer-icon"
            :class="{ open: open === index }"
            height="28"
            name="keyboard__arrow--right"
          />
        </ButtonComponent>
        <a
          v-else
          class="navigation__drawer-item"
          :href="getUrl(item)"
          :class="{ highlight: showHighlight(item) }"
          :aria-current="isAriaCurrent(item)"
          @click.prevent.stop="handleClick($event, item)"
          >{{ item.title }}
        </a>

        <div class="navigation__drawer-divider" />

        <ul
          class="navigation__drawer-subitems"
          :class="{
            collapsed: !isSelected(index)
          }"
        >
          <li
            v-for="(subitem, subindex) in item.items"
            :key="`subitem-${subindex}`"
            class="navigation__drawer-subitem"
          >
            <a
              v-if="subitem.path"
              class="navigation__drawer-subitem-link"
              :href="subitem.path"
              :class="{ highlight: showHighlight(subitem) }"
              :aria-current="isAriaCurrent(item)"
              v-html="subitem.title"
            />

            <router-link
              v-else
              :to="getRouteInfo(subitem)"
              :class="{ highlight: showHighlight(subitem) }"
              class="navigation__drawer-subitem-link"
              v-html="subitem.title"
            ></router-link>
          </li>
        </ul>
      </li>
    </ul>

    <ButtonComponent
      v-if="loggedIn"
      button-type="look-like-link"
      class="navigation__drawer-logout"
      :clickhandler="logout"
    >
      <Icon
        class="navigation__drawer-logout-icon"
        name="logout"
        height="15"
        up="3"
      />
      Ausloggen
    </ButtonComponent>
    <div v-if="loggedIn" class="navigation__drawer-divider" />
    <div
      v-if="$breakpoint.upToDesktop()"
      class="navigation__drawer-theme-toggle-wrapper"
    >
      <div class="navigation__drawer-theme-toggle-title">Farbschema wählen</div>
      <ThemeToggle
        v-model="isHighContrastModeActive"
        class="navigation__drawer-theme-toggle-item"
        data-cy="toggle-theme-switch"
        @click="toggleTheme"
      />
    </div>
  </div>
</template>

<script>
import { DARK } from 'src/components/common/settings/themes.js'
import { currentPageOrSubpage, exactCurrentPage } from './helper'
import { disableScrollY, enableScrollY, uniqueId } from 'src/lib/helpers'
import { mapState } from 'vuex'
import ButtonComponent from 'src/components/common/input/accessible_button.vue'
import Icon from '../icon/icon.vue'
import ThemeToggle from 'src/components/common/input/theme_toggle.vue'

export default {
  name: 'NavigationDrawer',
  components: {
    ThemeToggle,
    ButtonComponent,
    Icon
  },
  props: {
    navigationItems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      drawerOpen: false,
      touchPosition: undefined,
      menuId: 'mobile-menu-' + uniqueId(),
      open: this.navigationItems.findIndex(
        elem => this.currentPageOrSubpage(elem) && this.hasSubitems(elem)
      )
    }
  },
  computed: {
    ...mapState({
      loggedIn: state => state.authentication.loggedIn || false,
      theme: state => state.settings.theme,
      mobileMenuOpen: state => state.accountBar.mobileMenuOpen
    }),
    isHighContrastModeActive() {
      return this.theme === DARK
    }
  },
  watch: {
    $route() {
      this.closeMobileMenu()
    },
    mobileMenuOpen: 'updateMobileDrawer'
  },
  mounted() {
    this.closeMobileMenu()
  },
  beforeUnmount() {
    enableScrollY()
  },
  methods: {
    currentPageOrSubpage(item) {
      return currentPageOrSubpage(this.$router, this.$route, item)
    },
    exactCurrentPage(item) {
      return exactCurrentPage(this.$router, this.$route, item)
    },
    showHighlight(item) {
      if (this.hasSubitems(item)) {
        return false
      } else {
        return this.currentPageOrSubpage(item)
      }
    },
    isAriaCurrent(item) {
      if (this.hasSubitems(item)) {
        return undefined
      } else {
        return this.exactCurrentPage(item) ? 'page' : undefined
      }
    },
    hasSubitems(item) {
      return item.items && item.items.length > 0
    },
    getUrl(item) {
      if (item.path) {
        return item.path
      } else {
        return this.$router.resolve(item).href
      }
    },
    getRouteInfo(subitem) {
      if (subitem.tariffSlug) {
        return '/prepaid-tarife/' + subitem.tariffSlug
      } else {
        return subitem
      }
    },
    toggleMenu(index) {
      if (this.open < 0) {
        this.open = index
      } else if (this.open !== index) {
        this.open = -1
        setTimeout(() => {
          this.open = index
        }, 600)
      } else {
        this.open = -1
      }
    },
    handleClick(event, item) {
      if (item.path && item.path.startsWith('https')) {
        window.location.href = item.path
      } else {
        this.$router.push(item)
      }
    },
    logout() {
      this.$store.dispatch('authentication/logout')
    },
    updateMobileDrawer() {
      if (this.mobileMenuOpen) {
        this.showMobileDrawer()
      } else {
        this.hideMobileDrawer()
      }
    },
    hideMobileDrawer() {
      if (this.drawerOpen) {
        this.drawerOpen = false
        enableScrollY()
        this.$refs.navigationDrawer.style.transform = 'translateX(400px)'
        setTimeout(() => {
          if (this.$refs.navigationDrawer && !this.mobileMenuOpen) {
            this.$refs.navigationDrawer.style.visibility = 'hidden'
          }
        }, 500)
        document.removeEventListener('click', this.closeMobileMenu)
      }
    },
    showMobileDrawer() {
      if (this.$breakpoint.upToDesktop()) {
        this.drawerOpen = true
        disableScrollY()
        this.$refs.navigationDrawer.style.visibility = 'visible'
        this.$refs.navigationDrawer.style.transform = 'translateX(0px)'
        document.addEventListener('click', this.closeMobileMenu)
      }
    },
    handleNavItemTouch(evt) {
      this.touchPosition = evt.touches[0]
    },
    handleTouchMove(evt) {
      if (
        this.mobileMenuOpen &&
        this.touchPosition &&
        evt.touches[0].clientX - this.touchPosition.clientX > 5 &&
        Math.abs(evt.touches[0].clientY - this.touchPosition.clientY) < 5
      ) {
        this.closeMobileMenu()
      }
    },
    toggleTheme() {
      this.$store.dispatch('settings/toggleTheme')
    },
    isSelected(index) {
      return index === this.open
    },
    handleKeyEvent(event) {
      if (event.key === 'Tab') {
        const focusableList = this.$refs.navigationDrawer.querySelectorAll(
          'button, a, input:not([tabindex="-1"]), select, textarea, [tabindex]:not([tabindex="-1"])'
        )

        // do nothing if there is no element to focus
        if (focusableList.length < 1) {
          event.preventDefault()

          return
        }

        const last = focusableList.length - 1

        // focus first on tab last element
        if (event.shiftKey === false && event.target === focusableList[last]) {
          event.preventDefault()
          document.querySelector('#mobileMenuToggelButton').focus()

          return
        }
      } else if (event.key === 'Escape') {
        this.closeMobileMenuAndResetFocus()
      }
    },
    closeMobileMenu() {
      this.$store.commit('accountBar/closeMobileMenu')
    },
    closeMobileMenuAndResetFocus() {
      this.closeMobileMenu()

      const toggleButton = document.querySelector('#mobileMenuToggelButton')

      if (toggleButton && toggleButton.focus) {
        toggleButton.focus()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation__wrapper {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  max-width: 100%;
  height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  overflow-y: scroll;
  z-index: 6;
  background: var(--background-primary);
  transform: translateX(400px);
  box-shadow: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
    0 6px 30px 5px rgb(0 0 0 / 12%);
  transition: transform 0.5s ease;
}

.navigation__drawer {
  @include font-normal;

  padding-left: 0;
}

.navigation__drawer-divider {
  border-color: var(--root-color-inactive);
  color: var(--root-color-inactive);
  display: block;
  flex: 1 1 0;
  max-width: 100%;
  margin: -1px 0 -1px 20px;
  height: 0;
  max-height: 0;
  /* stylelint-disable-next-line declaration-block-no-shorthand-property-overrides */
  border: solid;
  border-width: thin 0 0;
}

.navigation__drawer-item-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .navigation__drawer-icon {
    transform: rotate(90deg);
    transition: transform 0.35s ease;
    color: $c-primary;
    padding: 0 2px 0 0;

    &.open {
      transform: rotate(-90deg);
      padding: 0 0 0 2px;
    }
  }
}

.navigation__drawer-item {
  align-items: flex-start;
  color: var(--root-color);
  padding-left: 15px;
  padding-top: 6px;
  padding-bottom: 2px;
  margin: 5px;
  text-decoration: none;

  .icon {
    color: var(--root-color);
  }
}

.navigation__drawer-subitems {
  max-height: 600px;
  overflow: hidden;
  transition: max-height 600ms;
  padding-left: 0;

  &.collapsed {
    max-height: 0;
    visibility: hidden;
  }
}

.navigation__drawer-subitem {
  display: flex;
  cursor: pointer;
  position: relative;
  list-style: none;
  font-size: calc-rem(14px);
  font-weight: 500;

  .subitem__icon {
    position: absolute;
    left: -20px;
  }
}

.navigation__drawer-subitem-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  color: var(--navigation-links-secondary);
  padding: 2px 11px 0 40px;
  margin: 5px;
  text-decoration: none;
}

.highlight {
  background-color: var(--root-color-inactive);
  color: var(--root-color);

  &:not(:focus) {
    box-shadow: 0 0 0 4px var(--root-color-inactive);
    padding-right: 12px;
    padding-left: 41px;
    margin: 5px 4px;
  }
}

.navigation__drawer-logout {
  margin-left: 27px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-right: 20px;
  color: var(--root-color);
  font-size: 1.1rem;

  :deep(.button-label) {
    font-weight: bold;
  }

  .navigation__drawer-logout-icon {
    margin-right: 4px;
  }
}

.navigation__drawer-theme-toggle-wrapper {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.navigation__drawer-theme-toggle-title {
  font-weight: bold;
  color: var(--root-color);
  padding-left: 20px;
  padding-top: 20px;
  cursor: default;
}

.navigation__drawer-theme-toggle-item {
  color: var(--root-color);
  padding-left: 20px;
  padding-top: 11px;
  padding-bottom: 7px;
  cursor: default;
}
</style>
