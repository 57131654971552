import selfcare_service_json from 'src/components/selfcare/service/home/services.json'

export function exactCurrentPage(router, route, item) {
  const fullPath = `${route.path}${route.hash}`

  if (item.name && router.resolve(item.name) === fullPath) {
    return true
  }

  if (item.path && item.path === fullPath) {
    return true
  }

  return false
}

export function currentPage(route, item) {
  if (item.name && item.name === route.name) {
    return true
  }

  if (item.path && item.path === route.path) {
    return true
  }

  return false
}

export function currentPageOrSubpage(router, route, item) {
  if (item.tariffSlug && !route.fullPath.includes(item.tariffSlug)) {
    return false
  }

  if (currentPage(route, item)) {
    return true
  }

  if (item.path && route.path.includes(item.path)) {
    return true
  }

  if (item.name && route.path.includes(router.resolve(item.name))) {
    return true
  }

  if (item && item.items) {
    for (const subitem of item.items) {
      if (subitem.name && subitem.name === route.name) {
        return true
      }
    }
  }

  if (item.name === 'SelfcareServiceHome') {
    for (const serviceItem of selfcare_service_json) {
      if (serviceItem.name === route.name) {
        return true
      }
    }
  }

  if (item.hiddenItems && item.hiddenItems.length > 0) {
    for (const hiddenItem of item.hiddenItems) {
      if (hiddenItem === route.name) {
        return true
      }
    }
  }

  return false
}
